<template>
  <div>
    <!-- 普外共识外链跳转 -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Default',
  data() {
    return {}
  },
  computed: {
    ...mapState(['Openid', 'userInfo']),
    uri() {
      return this.$route.query.Uri;
    },
    type() {
      return this.$route.query.type;
    }
  },
  created() {
    sessionStorage.setItem("path", '/default');
    if (this.uri) {
      sessionStorage.setItem("uri", this.uri);
    } else {
      sessionStorage.setItem("uri", '');
    }

    if(!this.userInfo) {
      this.$router.push('/entrance');
    } else {
      this.getCustmByOther();
    }
    
  },
  methods: {
    // type:4 模拟器对接
    getCustmByOther() {
      let link = sessionStorage.getItem("uri");
      this.$axios.post('/Api/Api/Web/GetWxUser/GetCustmByOther?OpenID=' + this.Openid + '&Uri=' + link + '&type=' + this.type).then(res => {
        window.location.href = res.Data;
      })
    }
  }
}
</script>